import { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MaterialTable,{ MTableToolbar } from "@material-table/core";
import { styled } from '@mui/material/styles';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import Clock from 'react-live-clock';
import ReactPlayer from "react-player";
import { Analytics } from '@vercel/analytics/react';
import Link from '@mui/material/Link';
import RadioIcon from '@mui/icons-material/Radio';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';



import { ThemeProvider, createTheme } from "@mui/material/styles";
import {RadioDataRecords} from "./RadioData";

const theme = createTheme({

  div: {paddingLeft:"10px",paddingRight:"10px"},

  paper: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '1rem',
    backgroundColor: '#091425',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '1rem',
  },
  submit: {
    margin: '2rem',
  },
  //Mobile Settings
  "@media (max-width: 800px)": {
    div: {paddingLeft:"10px",paddingRight:"10px"},
  }
  
  
});

export default function App() {

  const [hlsUrl, setHlsUrl] = useState(
    "https://c8.radioboss.fm:18033/stream"
  );

  const [stationTitle,setStationTitle] = useState("");
  const [isBusy, setIsBusy] = useState(true);
  const [isAIROn, setIsAIROn] = useState(true);
  const [stationListFinal,setStationListFinal] = useState(RadioDataRecords);
  const [showRadio, setShowRadio] = useState(true);

  useEffect(() => {
    console.log("useEffect");
    //Default play station
    playButton(51);
    
    checkAIRStatus();
    setIsBusy(false);

  }, []);

  function playButton(id){
    console.log("Station: "+id +" Name: "+RadioDataRecords[id-1].station);
    setStationTitle(RadioDataRecords[id-1].station);
    //This helps to display on browser title and shows on Car Console
    document.title = RadioDataRecords[id-1].station;
    setHlsUrl(RadioDataRecords[id-1].url);
    }

  const columns1 = [
    { title: "Language", field: "language",width:'30%'  },
    { title: "Station", field: "station",width:'50%'  },
    { title: "Time", field: "time" ,width:'20%' }
  ];

function filterLanguage(lang)
{
    console.log("Language: "+lang);
    getFinalPlayList(isAIROn,lang);
}

function showTracks(event) {
  setShowRadio(false);
  console.log("setting false");
}

function showRadio2(event) {
  setShowRadio(true);
  console.log("setting true");
}

function checkAIRStatus()
{
    const start = 6 * 60;  //6 AM
    //const end =  13 * 60; //11 PM // For testing purpose
    const end =  23 * 60; //11 PM
    const date = new Date(); 
   
    console.log("current Time:", date);
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
    console.log("IST Time:", ISTTime);

    const now = ISTTime.getHours() * 60 + ISTTime.getMinutes();
    console.log("start:"+start+" end:"+end+ " now:"+ now);

    if (now >= start && now <= end) {
      console.log("in time - Station On");
      setIsAIROn(true);
      getFinalPlayList(true,null);
    } else {
      console.log("out time - Station Off");
      setIsAIROn(false);
      getFinalPlayList(false,null);
    }
    
}

  function getFinalPlayList(isAIR,lang) {
    let result = [];

    if (isAIR) {
      result = RadioDataRecords;
      if (lang != null) {
        result = result.filter(
          (object1) => object1.language.indexOf(lang) !== -1
        );
      }
    } else {
      result = RadioDataRecords.filter(
        (object1) => object1.station.indexOf("AIR") === -1
      );
    }
    setStationListFinal(result);
  }

  function LinkComponent(search, display,color1)
  {
    if(display === "E" || display === "All")
    {
        return <>
        <Link color={color1} onClick={() => { filterLanguage(search) }}
             style={{ fontSize: 20}} >{display}</Link> &nbsp;&nbsp; 
      </>;
    }
    else{return <>
        <Link color={color1} onClick={() => { filterLanguage(search) }}
             style={{ fontSize: 24}} >{display}</Link> &nbsp; &nbsp;
      </>;}
    
  }


  return (
    <div align="center" 
    //style={{ backgroundColor: '#A0D2DB'}}
    >
      {showRadio ? (<div>
        <ThemeProvider theme={theme}>
      <Analytics />
    {isBusy ? (<div> Loading </div>) : (<>
      <br/>
       <Container component="main" maxWidth="sm">
      <div className={theme.paper}>
      <Box  > 

      <Grid container spacing={1} alignItems="center" justifyContent="center" >
          
          <Grid item xs={12}>
            <Typography variant="h5" display="block" >
              <b>Station: {stationTitle} </b></Typography>
            
          </Grid>

          <Grid item xs={12}>
          <ReactPlayer
              className="react-player"
              width="350px"
              height="50px"
              url={hlsUrl}
              playing={true}
              controls={true}
              onError={(e) => console.log("onError", e)}
            />
            </Grid>

          <Grid item xs={12}>
          <div style={{ maxWidth: "350px" }} >
            <MaterialTable
          title="Radio Stations"
          localization={{header: {
            actions: 'Play'
          },}}
          options={{
            pageSize: 99,
            paging: false,
            search: true,
            showTitle: false,
            toolbar: true,
            //thirdSortClick: true,
            
            headerStyle: {
              position: "sticky",
              top: "0",
              //backgroundColor:'red',
              fontSize: 12,
              fontWeight: 'bold',
            },
            maxBodyHeight: "450px",
            tableLayout: "fixed",
            sorting: false,
            rowStyle: x => {
             if (x.tableData.id % 2) {
                 return {backgroundColor: "#f2f2f2"}
             }},
            
             //rowStyle: {
              //fontSize: 14,
           // }
            
          }}
          columns={columns1}
          data={stationListFinal}
          //data = {getFinalPlayList()}
          actions={[
            {
              icon: PlayIcon,
              iconProps: { style: { fontSize: "24px", color: "green" } },
              tooltip: "Play",
              //onClick: (event, rowData) => alert("Play " + rowData.id)
              onClick: (event, rowData) => playButton(rowData.id)
            }
          ]}
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '#f2f2f2', }}>
                    <MTableToolbar {...props} searchFieldStyle={{width: 400}}/>
                </div>
            ) 
        }}
          
        /> </div>
          </Grid>

          <Grid item xs={5}>
            <b>
           IST :  
           <Clock
          format={' h:mm:ss A'}
          timezone={'Asia/Kolkata'}
          style={{fontSize: '1.0em'}}
          ticking={true} /></b>
           </Grid>

           <Grid item xs={5}><b>
           Local :  
           <Clock
          format={' h:mm:ss A'}
          style={{fontSize: '1.0em'}}
          ticking={true} /> </b>
           </Grid>

           {isAIROn ? (<>
            <Grid item xs={12}>
         
            {LinkComponent("Telugu","తె","#f44336")}
            {LinkComponent("Tamil","த","#00a152")}
            {LinkComponent("Hindi","हिं","#f44336")}
            {LinkComponent("Kannada","ಕ","#00a152")}
            {LinkComponent("Gujarati","ગુ","#f44336")}
            {LinkComponent("Malayalam","മ","#00a152")}
            {LinkComponent("Marathi","म","#f44336")}
            {LinkComponent("English","E","#00a152")}
            {LinkComponent(null,"All","#f44336")}
          
           </Grid>
           </>) : (<>
            <Grid item xs={12}><b>
           All India Radio works from 6 AM to 11 PM IST </b>
           </Grid>
           </>)}
          
          </Grid>   
         
      </Box> </div>
      </Container>

    </>)}
    </ThemeProvider>
    <br/>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={showTracks}
          endIcon={<LibraryMusicIcon />}
          >
          Music Tracks
          </Button>

      </div>):(<div>
        <br/>
    <b>Tibetan Healing Sounds</b><br/>
    <iframe src="https://archive.org/embed/tibetan-healing-sounds&playlist=1&list_height=200" 
          width="350" height="200" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen></iframe>
    <br/>
    <b>Mozart Complete Works (44 Full CDs)</b><br/>
    <iframe src="https://archive.org/embed/MozartComplete9Vol44FullCD.Flac&playlist=1&list_height=200" 
    width="350" height="200" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen></iframe>
    <br/>
    <b>Indian Background Flute Music</b><br/>
    <iframe src="https://archive.org/embed/IndianBackgroundFluteMusicInstrumentalMeditationMusicYogaMusicSpaMusicForRelaxation&playlist=1&list_height=300" 
    width="350" height="100" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen></iframe>
<br/>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={showRadio2}
          endIcon={<RadioIcon />}
          >
          Radio
          </Button>
      </div>)}
      
    
<br/>
    </div>
  );
}
